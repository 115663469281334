import { useUpdateTextMutation } from '~/types/types';
export default defineComponent({
  props: {
    isEditable: {
      type: Boolean,
      default: true
    },
    userInput: {
      type: Object,
      default: function _default() {}
    }
  },
  setup: function setup() {
    var _useUpdateTextMutatio = useUpdateTextMutation(),
      updateText = _useUpdateTextMutatio.mutate,
      onDone = _useUpdateTextMutatio.onDone;
    onDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    return {
      updateText: updateText
    };
  },
  methods: {
    valueChange: function valueChange(text) {
      this.updateText({
        userInputId: this.$props.userInput.id,
        text: text
      });
    }
  }
});